import {
  get,
  getWithArguments,
  postWithArguments,
  putWithArguments,
} from './baseEndpoint';

export const fetchAssets = postWithArguments('/operations/assets');

export const deleteAsset = postWithArguments('/operations/assets/delete');

export const moveAsset = postWithArguments('/operations/assets/move');

export const fetchDimension = (dimensionName, key, value) =>
  get(
    `/operations/analytics/dimension?dimension=${dimensionName}&key=${key}&value=${value}`
  );

export const fetchBrandRestaurants = postWithArguments(
  '/operations/brand/restaurants'
);

export const fetchBrand = getWithArguments('/operations/brand/get');

export const fetchBrands = postWithArguments('/operations/brand/search');

export const fetchTransactionMerchants = getWithArguments(
  '/operations/search-transaction-merchant'
);

export const fetchRestaurant = postWithArguments('/operations/restaurant/get');

export const fetchRestaurants = postWithArguments(
  '/operations/restaurant/search'
);

export const fetchClientSpendReport = postWithArguments(
  '/operations/spend-report/search'
);

export const fetchClientRebateOnlySummary = postWithArguments(
  '/transactions/client-rebate/summary'
);

export const fetchClientRebateOnlyTransactions = postWithArguments(
  '/transactions/client-rebate/report'
);

export const fetchIngestionReport = postWithArguments(
  '/operations/ingestion-summary/search'
);

export const fetchMonthlyData = postWithArguments(
  '/operations/transaction-billing-detail/search'
);

export const fetchTransaction = postWithArguments(
  '/operations/transaction-detail/find'
);

export const fetchVolumeReport = postWithArguments(
  '/operations/volume-report/search'
);

export const fetchExportStatus = postWithArguments('/operations/export-status');

export const promoteBrand = postWithArguments(
  '/operations/assets/promote/brand'
);

export const promoteRestaurant = postWithArguments(
  '/operations/assets/promote/restaurant'
);

export const queueVolumeExport = postWithArguments(
  '/operations/volume-report/export'
);

export const queueSpendExport = postWithArguments(
  '/operations/spend-report/export'
);

export const tagAsset = putWithArguments('/operations/assets/tag');
