import ReduxThunk from 'redux-thunk';
import settings from './settings.middleware.js';
import themes from './themes.middleware.js';
import { userMiddleware } from './user.middleware.js';
import reduxMulti from 'redux-multi';

export default [
  reduxMulti,
  ReduxThunk,
  userMiddleware,
  settings,
  themes,
];
