import { getWithArguments, postWithArguments } from './baseEndpoint';

export const cueRedemptionReport = postWithArguments(
  '/mydinova/redemptions/report'
);

export const fetchRedemptions = getWithArguments('/mydinova/redemptions');

export const updateRedemptionStatus = postWithArguments(
  '/mydinova/redemptions/update'
);
