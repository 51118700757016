import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Sidebar from './Sidebar';
import Offsidebar from './Offsidebar';
import Footer from './Footer';
import Modal from './Modal';

const Base = props => (
  <div className="wrapper">
    <Header />

    <Sidebar />

    <Offsidebar />

    <section className="section-container">
      { props.children }
    </section>

    <Footer />
    <Modal />
  </div>
);

Base.propTypes = {
  children: PropTypes.node,
};

export default Base;
