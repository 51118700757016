import { getToken } from './jwt';
import { logError } from './logger';

const { REACT_APP_API_BASE_PATH: API_BASE_PATH } = process.env;

const defaultErrorHandler = response => {
  const { status, statusText } = response;
  throw new Error({ status, statusText });
};

export const request = async (
  { endpoint = '', method = 'GET', payload },
  onError = () => undefined
) => {
  const Authorization = getToken();
  const auth = Authorization ? { Authorization } : {};
  const body = payload ? { body: JSON.stringify(payload) } : '{}';
  const params = {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      ...auth,
    },
    ...body,
  };
  try {
    console.log(`requesting: ${API_BASE_PATH}${endpoint}`);
    const response = await fetch(`${API_BASE_PATH}${endpoint}`, params);
    if (response.ok) {
      return await response.json();
    } else {
      return await onError(response) || defaultErrorHandler(response);
    }
  } catch (err) {
    logError('API request error: ', err);
    throw err;
  }
};