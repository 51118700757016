import moment from 'moment';

export const formatDate = (date, format = 'MM/DD/YYYY') =>
  moment(date)
    .utc()
    .format(format); 

export const formatCurrency = (
  amount,
  currency = 'USD',
  nanDisplay = '---'
) => {
  if (!amount || Number.isNaN(Number(amount))) return nanDisplay;
  if (currency && currency !== 'USD')
    return `${Number(amount).toLocaleString('en-US')} ${currency}`;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
  });

  return formatter.format(amount);
};

export const formatPercent = x => {
  const num = parseFloat(x);
  return Number.isNaN(num) ? '---' : `${(num * 100).toFixed(2)}%`;
};
