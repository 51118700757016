import { get, getWithArguments, post, postWithArguments } from './baseEndpoint';

//MyDinova Clients
export const createConnection = postWithArguments('/mydinova/connections/create');

export const createSubsidiary = postWithArguments('/mydinova/subsidiaries/create');

export const creditUserPoints = postWithArguments('/mydinova/users/creditPoints');

/**
 * @param {number} userId - User ID
 */
export const deactivateUser = postWithArguments('/mydinova/users/deactivate');

export const destroyCampaign = postWithArguments('/mydinova/campaigns/destroy');

export const disableUserMfa = postWithArguments('/mydinova/users/disable-mfa');

export const fetchClient = code =>
  getWithArguments(`/mydinova/clients/get`)({ code });

export const fetchCampaignsData = getWithArguments('/mydinova/campaigns');

export const fetchIssuers = () => get('/mydinova/issuers');

export const fetchConnectionsReport = postWithArguments(
  '/mydinova/connections'
);

export const fetchRegistrationsReport = postWithArguments(
  '/mydinova/registrations'
);

export const getMyDinovaUser = id =>
  getWithArguments(`/mydinova/users/get`)({ id });

export const getUserTransactions = getWithArguments('/mydinova/users/transactions');

export const saveCampaign = postWithArguments('/mydinova/campaigns/save');

export const searchClients = postWithArguments('/mydinova/clients/search');

export const searchUsers = ({ searchText, filters }) =>
  post('/mydinova/users/search', { searchtext: searchText, filters });

export const updateClient = postWithArguments('/mydinova/clients/update');

export const updateConnection = postWithArguments('/mydinova/connections/update');

export const updateSubsidiary = postWithArguments('/mydinova/subsidiaries/update');

export const updateUserRoles = postWithArguments('/mydinova/users/update-roles');
