import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';

const publicPages = ['/login', '/recover', '/preview'];

const Routes = props =>
  publicPages.includes(props.location.pathname) ? (
    <PublicRoutes {...props} />
  ) : (
    <PrivateRoutes {...props} />
  );

Routes.propTypes = {
  location: PropTypes.object,
};

export default withRouter(Routes);
