const ADMIN = 1;
const OPERATIONS_ADMIN = 10;
const MY_DINOVA_ADMIN = 11;
const ONBOARDING_ADMIN = 12;
const VOXCODE_USER = 13;
const RESTAURANT_SERVICES_USER = 14;
const REBATE_REPORT_USER = 15;

const checkPolicy = (roles = []) => user => {
  return authenticated(user) &&
  user.roles &&
  [ADMIN, ...roles].some(role => user.roles.includes(role));
};

// checks for onboarding S3 bucket; should not be set for UAT environment
const onboardingPolicy = roles => user => {
  return process.env.REACT_APP_ONBOARDING_BUCKET && checkPolicy(roles)(user);
};

export const authenticated = user => Boolean(user.authenticated_time);
export const admin = checkPolicy();
export const canViewRestaurantVolume = checkPolicy([OPERATIONS_ADMIN, RESTAURANT_SERVICES_USER]);
export const myDinovaAdmin = checkPolicy([MY_DINOVA_ADMIN]);
export const operationsAdmin = checkPolicy([OPERATIONS_ADMIN]);
export const onboardingAdmin = onboardingPolicy([ONBOARDING_ADMIN]);
export const onboardingUser = onboardingPolicy([ONBOARDING_ADMIN, VOXCODE_USER]);
export const rebateReportUser = onboardingPolicy([REBATE_REPORT_USER]);