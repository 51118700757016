/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.3
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './store/store';
import Routes from './Routes'; // App Routes
import './Vendor'; // Vendor dependencies
import './styles/bootstrap.scss';
import './styles/app.scss';

export const store = configureStore();

class App extends Component {
  render() {
    // specify base href from env varible 'PUBLIC_URL'
    // use only if application isn't served from the root
    // for development it is forced to root only
    /* global PUBLIC_URL */
    const basename =
      process.env.NODE_ENV === 'development' ? '/' : PUBLIC_URL || '/';

    return (
      <Provider store={store}>
        <BrowserRouter basename={basename}>
          <Routes />
        </BrowserRouter>
      </Provider>
    );
  }
}

export default App;
