import React, { Suspense, lazy, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { store } from './App';
import PageLoader from './components/Common/PageLoader';
import Base from './components/Layout/Base';
import PrivateRoute from './components/Common/PrivateRoute';
import { useToggle } from './hooks/useToggle';
import { checkAndRefreshToken } from './store/actions/actions';
import {
  authenticated,
  canViewRestaurantVolume,
  myDinovaAdmin,
  onboardingUser,
  operationsAdmin,
} from './policies';

const locationProps = { location: PropTypes.object };

/* Used to render a lazy component with react-router */
// eslint-disable-next-line react/display-name
const waitFor = Tag => props => <Tag {...props} />;

// TODO: merge asset management pages
const AssetManagement = lazy(() => import('./components/Pages/Onboarding/AssetManagement'));
const CampaignManagement = lazy(() => import('./components/Pages/MyDinova/CampaignManagement'));
const ClientSpend = lazy(() => import('./components/Pages/Operations/ClientSpend'));
const Connections = lazy(() => import('./components/Pages/MyDinova/Connections'));
const IngestionSummary = lazy(() => import('./components/Pages/Operations/IngestionSummary'));
const MerchantNumber = lazy(() => import('./components/Pages/Operations/MerchantNumber'));
const MyDinovaClient = lazy(() => import('./components/Pages/MyDinova/Client'));
const MyDinovaClientSearch = lazy(() => import('./components/Pages/MyDinova/ClientSearch'));
const MyDinovaUser = lazy(() => import('./components/Pages/MyDinova/User'));
const MyDinovaUserSearch = lazy(() => import('./components/Pages/MyDinova/UserSearch'));
const RebateOnly = lazy(() => import('./components/Pages/Operations/RebateOnly'));
const Redemptions = lazy(() => import('./components/Pages/MyDinova/Redemptions'));
const Registrations = lazy(() => import('./components/Pages/MyDinova/Registrations'));
const RestaurantVolume = lazy(() => import('./components/Pages/Operations/RestaurantVolume'));
const SingleView = lazy(() => import('./components/SingleView/SingleView'));
const SubMenu = lazy(() => import('./components/SubMenu/SubMenu'));

const SessionManager = props => {
  const { location } = props;
  const currentKey = location.pathname.split('/')[1] || '/';
  const timeout = { enter: 500, exit: 500 };
  const animationName = 'rag-fadeIn';
  const [tokened, toggleTokened] = useToggle(false);

  useEffect(() => {
    toggleTokened(false);
    (async () => {
      await checkAndRefreshToken(store);
      toggleTokened(true);
    })();
  }, [location]);

  return (
    <Base>
      <TransitionGroup>
        <CSSTransition
          key={currentKey}
          timeout={timeout}
          classNames={animationName}
          exit={false}
        >
          <div>{tokened ? <PrivateRoutes {...props} /> : <PageLoader />}</div>
        </CSSTransition>
      </TransitionGroup>
    </Base>
  );
};

SessionManager.propTypes = locationProps;

const PrivateRoutes = ({ location }) => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch location={location}>
        <PrivateRoute
          path="/my-dinova"
          exact={true}
          component={waitFor(MyDinovaUserSearch)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/my-dinova/user/:id"
          component={waitFor(MyDinovaUser)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/my-dinova/campaigns/manage"
          exact={true}
          component={waitFor(CampaignManagement)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/my-dinova/client/search"
          exact={true}
          component={waitFor(MyDinovaClientSearch)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/my-dinova/client/:code"
          component={waitFor(MyDinovaClient)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/my-dinova/redemptions"
          component={waitFor(Redemptions)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/my-dinova/connections"
          component={waitFor(Connections)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/my-dinova/registrations"
          component={waitFor(Registrations)}
          policy={myDinovaAdmin}
        />
        <PrivateRoute
          path="/operations/client-spend"
          component={waitFor(ClientSpend)}
          policy={operationsAdmin}
        />
        <PrivateRoute
          path="/operations/rebate-only"
          component={waitFor(RebateOnly)}
          policy={operationsAdmin}
        />
        <PrivateRoute
          path="/operations/restaurant-volume"
          component={waitFor(RestaurantVolume)}
          policy={canViewRestaurantVolume}
        />
        <PrivateRoute
          path="/operations/ingestion-summary"
          component={waitFor(IngestionSummary)}
          policy={operationsAdmin}
        />
        <PrivateRoute
          path="/operations/merchant-number"
          component={waitFor(MerchantNumber)}
          policy={operationsAdmin}
        />
        <PrivateRoute
          path="/onboarding/asset-management"
          component={waitFor(AssetManagement)}
          policy={onboardingUser}
        />
        <PrivateRoute
          exact={true}
          path="/"
          component={waitFor(SingleView)}
          policy={authenticated}
        />
        <PrivateRoute
          path="/submenu"
          component={waitFor(SubMenu)}
          policy={authenticated}
        />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

PrivateRoutes.propTypes = locationProps;

export default SessionManager;
