import React, { useState } from 'react';
import { string, shape, func, node, oneOfType } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { closeModal } from '../../store/actions/actions';
import { connect } from 'react-redux';

const GenericModal = ({ modal, actions }) => {
  return (
    <Modal isOpen={modal.open}>
      <ModalHeader>{modal.header}</ModalHeader>
      <ModalBody>{modal.body}</ModalBody>
      <ModalFooter>
        <Button onClick={actions.closeModal}>Close</Button>
      </ModalFooter>
    </Modal>
  );
};

const DialogModal = ({ modal, actions }) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    try {
      await modal.onSubmit(e);
      actions.closeModal();
    } catch (err) {
      actions.handleError && actions.handleError();
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={modal.open}>
      <ModalHeader>{modal.header}</ModalHeader>
      <form onSubmit={onSubmit}>
        <ModalBody>{modal.body}</ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary" disabled={loading}>
            Save
          </Button>
          <Button onClick={actions.closeModal}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const ModalHandler = props => {
  if (props.modal.type === 'form') return <DialogModal {...props} />;
  return <GenericModal {...props} />;
};

GenericModal.defaultProps = {
  modal: {},
};

const modalProps = {
  modal: shape({
    header: string,
    body: oneOfType([string, node]),
  }),
  actions: shape({
    closeModal: func,
    handleError: func,
  }),
};

ModalHandler.propTypes = modalProps;
GenericModal.propTypes = modalProps;
DialogModal.propTypes = modalProps;

const mapStateToProps = ({ modal }) => ({ modal });
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ closeModal }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalHandler);
