import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';

import * as actions from '../../store/actions/actions';

class Offsidebar extends Component {
  state = {
    activeTab: 'settings',
    offsidebarReady: false,
  };

  componentDidMount() {
    // When mounted display the offsidebar
    this.setState({ offsidebarReady: true });
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleSettingCheckbox = event => {
    this.props.actions.changeSetting(event.target.name, event.target.checked);
  };

  handleThemeRadio = event => {
    this.props.actions.changeTheme(event.target.value);
  };

  render() {
    return (
      this.state.offsidebarReady && (
        <aside className="offsidebar">
          {/* START Off Sidebar (right) */}
          <nav>
            <div>
              {/* Nav tabs */}
              <Nav tabs justified>
                <NavItem>
                  <NavLink
                    className={
                      this.state.activeTab === 'settings' ? 'active' : ''
                    }
                    onClick={() => {
                      this.toggle('settings');
                    }}
                  >
                    <em className="icon-equalizer fa-lg" />
                  </NavLink>
                </NavItem>
              </Nav>
              {/* Tab panes */}
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="settings">
                  <div className="p-3 text-center">
                    <Button
                      color="primary"
                      onClick={this.props.actions.logoutUser}
                      title="Log Out"
                      block={true}
                    >
                      Log Out
                    </Button>
                  </div>
                  <h3 className="text-center text-thin mt-4">Settings</h3>
                  <div className="p-2">
                    <h4 className="text-muted text-thin">Themes</h4>
                    <div className="row row-flush mb-2">
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-a.css'
                              }
                              value="themes/theme-a.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-info" />
                              <span className="color bg-info-light" />
                            </span>
                            <span className="color bg-white" />
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-b.css'
                              }
                              value="themes/theme-b.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-green" />
                              <span className="color bg-green-light" />
                            </span>
                            <span className="color bg-white" />
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-c.css'
                              }
                              value="themes/theme-c.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-purple" />
                              <span className="color bg-purple-light" />
                            </span>
                            <span className="color bg-white" />
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-d.css'
                              }
                              value="themes/theme-d.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-danger" />
                              <span className="color bg-danger-light" />
                            </span>
                            <span className="color bg-white" />
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-e.css'
                              }
                              value="themes/theme-e.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-info-dark" />
                              <span className="color bg-info" />
                            </span>
                            <span className="color bg-gray-dark" />
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-f.css'
                              }
                              value="themes/theme-f.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-green-dark" />
                              <span className="color bg-green" />
                            </span>
                            <span className="color bg-gray-dark" />
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-g.css'
                              }
                              value="themes/theme-g.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-purple-dark" />
                              <span className="color bg-purple" />
                            </span>
                            <span className="color bg-gray-dark" />
                          </label>
                        </div>
                      </div>
                      <div className="col-3 mb-3">
                        <div className="setting-color">
                          <label>
                            <input
                              type="radio"
                              name="setting-theme"
                              checked={
                                this.props.theme.path === 'themes/theme-h.css'
                              }
                              value="themes/theme-h.css"
                              onChange={this.handleThemeRadio}
                            />
                            <span className="icon-check" />
                            <span className="split">
                              <span className="color bg-danger-dark" />
                              <span className="color bg-danger" />
                            </span>
                            <span className="color bg-gray-dark" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </div>
          </nav>
          {/* END Off Sidebar (right) */}
        </aside>
      )
    );
  }
}

Offsidebar.propTypes = {
  actions: PropTypes.object,
  settings: PropTypes.object,
  theme: PropTypes.object,
};

const mapStateToProps = state => ({
  settings: state.settings,
  theme: state.theme,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Offsidebar);
