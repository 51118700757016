import {
  authenticated,
  myDinovaAdmin,
  operationsAdmin,
  onboardingUser,
} from './policies';

const Menu = [
  {
    name: 'Home',
    path: '/',
    exact: true,
    policies: [authenticated],
    icon: 'icon-grid',
  },
  {
    name: 'Operations',
    icon: 'icon-wrench',
    policies: [operationsAdmin],
    label: { value: 1, color: 'info' },
    submenu: [
      {
        name: 'Restaurant Volume',
        path: '/operations/restaurant-volume',
      },
      {
        name: 'Client Spend',
        path: '/operations/client-spend',
      },
      {
        name: 'Rebate Only',
        path: '/operations/rebate-only',
      },
      {
        name: 'Merchant Number',
        path: '/operations/merchant-number',
      },
      {
        name: 'Ingestion Summary',
        path: '/operations/ingestion-summary',
      },
    ],
  },
  {
    name: 'myDinova',
    icon: 'fa fa-utensils',
    policies: [myDinovaAdmin],
    label: { value: 1, color: 'info' },
    submenu: [
      {
        name: 'Users',
        path: '/my-dinova',
        exact: true,
      },
      {
        name: 'Campaigns',
        path: '/my-dinova/campaigns/manage',
      },
      {
        name: 'Clients',
        path: '/my-dinova/client/search',
      },
      {
        name: 'Redemptions',
        path: '/my-dinova/redemptions',
      },
      {
        name: 'Connections',
        path: '/my-dinova/connections',
      },
      {
        name: 'Registrations',
        path: '/my-dinova/registrations',
      },
    ],
  },
  {
    name: 'Onboarding',
    icon: 'fa fa-images',
    policies: [onboardingUser],
    label: { value: 1, color: 'info' },
    submenu: [
      {
        name: 'Brand/Restaurant Assets',
        path: '/onboarding/asset-management',
        exact: true,
      },
    ],
  },
];

export default Menu;
