import moment from 'moment';

const getSortPrimer = sortType => {
  switch (sortType) {
    case 'string':
      return a => (a || '').toUpperCase();
    case 'number':
      return a => parseFloat(a) || 0;
    case 'boolean':
      return a => !a;
    case 'currency':
      return a => (a ? parseFloat(a.replace('$', '')) : -1);
    case 'datetime':
      return a =>
        moment(a).isValid()
          ? parseInt(moment(a).format('X'))
          : 8640000000000000; //return max date for non dates
    default:
      throw new Error(`Unexpected sort type: ${sortType}`);
  }
};

export const sortBy = (sortField, sortType, sortDirection, mapper) => {
  const getSortValue = x => (mapper ? mapper(x) : x[sortField]);

  const reverse = sortDirection === 'DESC' ? -1 : 1;

  const getType = val => {
    if (val instanceof Date || moment.isMoment(val)) return 'datetime';

    return typeof val;
  };

  return (a, b) => {
    const aSortValue = getSortValue(a);
    const bSortValue = getSortValue(b);
    sortType = sortType || getType(aSortValue);
    const primer = getSortPrimer(sortType);
    const aValue = primer(aSortValue);
    const bValue = primer(bSortValue);
    return reverse * ((aValue > bValue) - (bValue > aValue));
  };
};
