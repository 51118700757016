import { request } from '../../utils/request';

const toEncodedArguments = entry => {
  const [key, value] = entry.map(encodeURIComponent);
  return `${key.toLowerCase()}=${value}`;
};

export const get = endpoint => request({ method: 'GET', endpoint });

/**
 * Returns function to be called with arguments to be passed to get
 * @param {string} endpoint
 * @returns {function}
 */
export const getWithArguments = endpoint => async (payload = {}) => {
  const args = Object.entries(payload).map(toEncodedArguments).join('&');
  return await get(`${endpoint}?${args}`);
};

export const post = (endpoint, payload, onError) =>
  request({ endpoint, method: 'POST', payload }, onError);

/**
 * Returns function to be called with arguments to be passed to post
 * @param {string} endpoint
 * @returns {function}
 */
export const postWithArguments = endpoint => (payload, onError) => post(endpoint, payload, onError);

export const put = (endpoint, payload) =>
  request({ endpoint, method: 'PUT', payload });

/**
 * Returns function to be called with arguments to be passed to put
 * @param {string} endpoint
 * @returns {function}
 */
export const putWithArguments = endpoint => payload => put(endpoint, payload);
