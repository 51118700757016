export const ACCEPTED_ASSET_TYPES = [
  'image/jpg',
  'image/jpeg',
  'image/gif',
  'image/png',
];

export const BRAND = 'brand';

export const CREDIT_CARD_TYPES = [
  { id: 1, value: 'American Express' },
  { id: 2, value: 'Visa' },
  { id: 3, value: 'MasterCard' },
  { id: 4, value: 'Diners Club' },
  { id: 5, value: 'AirPlus' },
];

export const FAILED = 'FAILED';

export const HASH_TYPES = [
  { id: -1, value: 'Not Specified' },
  { id: 1, value: 'MD5' },
  { id: 2, value: 'Sha256' },
];

export const LOGIN_TIME_LIMIT = 10;

export const POINTS_TYPES = [
  { id: 2, value: 'Admin Points' },
  { id: 6, value: 'Sweepstakes' },
];

export const PENDING = 'PENDING';

export const PROXY_TYPES = [
  { id: 1, value: 'HASH' },
  { id: 2, value: 'Employee Id' },
  { id: 3, value: 'Email' },
];

export const RESTAURANT = 'restaurant';

export const REFRESH_TIME_LIMIT = 10;

export const SUCCESS = 'SUCCESS';
