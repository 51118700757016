import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'core-js/es/array';
import 'raf/polyfill';

import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

import './i18n';

createRoot(document.getElementById('app')).render(<App />);
