import { combineReducers } from 'redux';

import modalReducer from './modal.reducer.js';
import settingsReducer from './settings.reducer.js';
import themesReducer from './themes.reducers.js';
import userReducer from './user.reducer.js';

export default combineReducers({
  modal: modalReducer,
  settings: settingsReducer,
  theme: themesReducer,
  user: userReducer,
});
