import moment from 'moment';
import {
  authenticateUser,
  recoverPassword,
  resetToken,
} from '../../clients/dashboardClient';
import { initialState } from '../reducers/user.reducer';
import { parseJwt } from '../../utils/jwt';
import { logError } from '../../utils/utils';
import { LOGIN_TIME_LIMIT, REFRESH_TIME_LIMIT } from '../../constants';

export const CANCEL_MFA = 'CANCEL_MFA';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REFRESH_USER = 'REFRESH_USER';
export const REFRESH_TIME = 'REFRESH_TIME';
export const REFRESH_USER_RUNNING = 'REFRESH_USER_RUNNING';
export const REQUEST_MFA = 'REQUEST_MFA';

export const checkAndRefreshToken = async store => {
  const storedUser = JSON.parse(localStorage.getItem('user')) || {};
  const tokenStart = storedUser.authenticated_time;
  const refreshDuration = moment().diff(tokenStart, 'minutes');
  const loginDuration = moment().diff(tokenStart, 'hours');
  if (!storedUser || loginDuration >= LOGIN_TIME_LIMIT) {
    await store.dispatch(logoutUser());
  } else if (
    tokenStart &&
    refreshDuration >= REFRESH_TIME_LIMIT &&
    !store.getState().user.refreshingUser &&
    storedUser.tokens &&
    storedUser.tokens.refresh
  ) {
    await store.dispatch(refreshUser(storedUser.tokens.refresh));
  }
};

const cleanUserData = data => {
  const profile = parseJwt(data.tokens.id);

  return {
    username: data.userName,
    tokens: data.tokens,
    name: fullName(data.name),
    roles: (profile['custom:roles'] || '').split(',').map(Number),
    authenticated_time: moment().format(),
  };
};

const fullName = ({ first, last, prefix }) =>
  [prefix, first, last].filter(Boolean).join(' ');

export function cancelMFA() {
  return { type: CANCEL_MFA };
}

export function loginUser(credentials) {
  return async dispatch => {
    try {
      const data = await authenticateUser(credentials);
      if (data.user) {
        const user = cleanUserData(data.user);
        dispatch({ type: LOGIN_USER, user });
        localStorage.setItem('user', JSON.stringify(user));
      } else if (data.mfaToken) {
        dispatch({ type: REQUEST_MFA, data });
      } else {
        throw new Error('unhandled exception logging in user');
      }
    } catch (error) {
      return error;
    }
  };
}

export function recoverUserPassword(email) {
  return async () => {
    try {
      await recoverPassword(email);
      return {};
    } catch (error) {
      //TODO: refactor: returned message does not match normal behavior returned value type and is not used
      return error;
    }
  };
}

export function logoutUser() {
  localStorage.setItem('user', JSON.stringify(initialState));
  return { type: LOGOUT_USER };
}

export function refreshUser(refreshToken) {
  return async dispatch => {
    try {
      dispatch(refreshStart());

      const tokens = await resetToken(refreshToken);
      //Set token in localstorage
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (!storedUser) throw new Error('user not found');
      storedUser.tokens.id = tokens.id;
      storedUser.authenticated_time = moment().format();
      localStorage.setItem('user', JSON.stringify(storedUser));

      dispatch(refreshStop());

      dispatch({ type: REFRESH_USER, payload: tokens.id });

      return;
    } catch (error) {
      dispatch(logoutUser());
      logError(error);
      return error;
    }
  };
}

export const refreshStart = () => {
  return { type: REFRESH_USER_RUNNING, payload: true };
};

export const refreshStop = () => {
  return { type: REFRESH_USER_RUNNING, payload: false };
};

export const refreshTime = () => {
  return { type: REFRESH_TIME, payload: moment() };
};
