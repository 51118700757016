import { camelCase } from 'lodash';

export const camelCaseKeys = obj =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => {
      const camelCaseKey = camelCase(key);
      if (acc[camelCaseKey] !== undefined)
        throw new Error('Results in overwriting key');
      return { ...acc, [camelCaseKey]: value };
    },
    {}
  );

export const sliceObject = (obj, keys) =>
  keys.reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});
