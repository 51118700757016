import {
  LOGIN_USER,
  REFRESH_USER,
  REFRESH_TIME,
  REFRESH_USER_RUNNING,
  checkAndRefreshToken,
} from '../actions/actions';

const AUTH_ACTIONS = [
  REFRESH_USER_RUNNING,
  REFRESH_TIME,
  REFRESH_USER,
  LOGIN_USER,
];

export const userMiddleware = store => next => async action => {
  if (!AUTH_ACTIONS.includes(action.type)) await checkAndRefreshToken(store);
  return next(action);
};
