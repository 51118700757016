import React from 'react';
import { connect } from 'react-redux';
import { func, object } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
  component: Component,
  location,
  policy,
  user,
  ...rest
}) => {
  const renderComponent = props =>
    policy(user) ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );

  return <Route {...rest} render={renderComponent} />;
};

PrivateRoute.defaultProps = { policy: () => false };

PrivateRoute.propTypes = {
  component: func.isRequired,
  location: object.isRequired,
  policy: func,
  user: object.isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(PrivateRoute);
