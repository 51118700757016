import { post } from './baseEndpoint';
import { request } from '../../utils/request';

export const handleMFAError = async response => {
  if (response.status === 411) return await response.json();
};

export const recoverPassword = async email => {
  try {
    await post('/users/forgotpassword', { email });
    return true;
  } catch (error) {
    throw error.status;
  }
};

export const authenticateUser = async ({ username, password, mfa }) => {
  try {
    return mfa
      ? await post('/users/login', { mfa })
      : await request(
          {
            endpoint: '/users/login',
            method: 'POST',
            payload: { userName: username, password },
          },
          handleMFAError
        );
  } catch (error) {
    throw error.status;
  }
};

export const resetToken = async refreshToken => {
  try {
    const { tokens } = await post('/users/login', { refreshToken });
    return tokens;
  } catch (error) {
    throw error.status;
  }
};
