export const SET_MODAL = 'SET_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export function closeModal() {
  return { type: CLOSE_MODAL, open: false };
}

export function setModalContent(modal) {
  return { type: SET_MODAL, modal };
}
