import {
  CANCEL_MFA,
  LOGIN_USER,
  LOGOUT_USER,
  REFRESH_TIME,
  REFRESH_USER_RUNNING,
  REFRESH_USER,
  REQUEST_MFA,
} from '../actions/actions';

const initialModalState = {
  mfaDialog: false,
  mfaToken: '',
  sentTo: undefined,
};

export const initialState = {
  ...initialModalState,
  authenticated_time: undefined,
  authenticated: false,
  refreshingUser: false,
};

const mfaReducer = (state, { mfaToken, sentTo }) => ({
  ...state,
  mfaDialog: true,
  mfaToken,
  sentTo,
});


const userReducer = (state = initialState, action) => {
  let moment = undefined;
  switch (action.type) {
    case LOGIN_USER:
    case REFRESH_USER:
      return {
        ...state,
        ...action.user,
        ...initialModalState,
        authenticated: true,
      };
    case CANCEL_MFA:
      return { ...state, ...initialModalState };
    case LOGOUT_USER:
      return initialState;
    case REFRESH_USER_RUNNING:
      return { ...state, refreshingUser: action.payload };
    case REFRESH_TIME:
      if (action.payload) {
        moment = action.payload;
      }
      return { ...state, authenticated_time: moment };
    case REQUEST_MFA:
      return mfaReducer(state, action.data);
    default:
      return state;
  }
};

export default userReducer;
