import { SET_MODAL, CLOSE_MODAL } from '../actions/actions';

const initialState = {
  header: null,
  body: null,
  open: false,
  type: null,
  onSubmit: null,
};

const setModal = modal => ({ ...modal, open: true });

export default (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_MODAL:
      return initialState;
    case SET_MODAL:
      return setModal(action.modal);
    default:
      return state;
  }
};
