import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation, Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { logError } from '../../utils/logger';
import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';
import * as actions from '../../store/actions/actions';
import Menu from '../../Menu.js';

const permitted = (user, { name, policies }) => {
  if (!policies || !policies.length) {
    logError(`Side bar item '${name}' missing policy`);
    return false;
  }
  return policies.some(f => f(user));
};

/** Component to display headings on sidebar */
const SidebarItemHeader = ({ item }) => (
  <li className="nav-heading">
    <span>
      <Trans i18nKey={item.translate}>{item.heading}</Trans>
    </span>
  </li>
);

/** Normal items for the sidebar */
const SidebarItem = ({ item, isActive }) => (
  <li className={isActive ? 'active' : ''}>
    <Link to={item.path} title={item.name}>
      {item.icon && <em className={item.icon} />}
      <span>
        <Trans i18nKey={item.translate}>{item.name}</Trans>
      </span>
    </Link>
  </li>
);

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({ item, isActive, handler, children, isOpen }) => (
  <li className={isActive ? 'active' : ''}>
    <div className="nav-item" onClick={handler}>
      {item.icon && <em className={item.icon} />}
      <span>
        <Trans i18nKey={item.translate}>{item.name}</Trans>
      </span>
    </div>
    <Collapse isOpen={isOpen}>
      <ul id={item.path} className="sidebar-nav sidebar-subnav">
        {children}
      </ul>
    </Collapse>
  </li>
);

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({ item }) => (
  <li className="sidebar-subnav-header">{item.name}</li>
);

class Sidebar extends Component {
  state = {
    collapse: {},
  };

  componentDidMount() {
    // pass navigator to access router api
    SidebarRun(this.navigator, this.closeSidebar);
    // prepare the flags to handle menu collapsed states
    this.buildCollapseList();

    // Listen for routes changes in order to hide the sidebar on mobile
    this.props.history.listen(this.closeSidebar);
  }

  closeSidebar = () => {
    this.props.actions.toggleSetting('asideToggled');
  };

  /** prepare initial state of collapse menus. Doesnt allow same route names */
  buildCollapseList = () => {
    let collapse = {};
    Menu.filter(({ heading }) => !heading).forEach(
      ({ name, path, submenu }) => {
        collapse[name] = this.routeActive(
          submenu ? submenu.map(({ path }) => path) : path
        );
      }
    );
    this.setState({ collapse });
  };

  navigator = route => {
    this.props.history.push(route);
  };

  routeActive(paths, exact) {
    paths = Array.isArray(paths) ? paths : [paths];
    const { pathname } = this.props.location;
    return exact
      ? paths.some(p => p === pathname)
      : paths.some(p => pathname.indexOf(p) > -1);
  }

  toggleItemCollapse(stateName) {
    Object.entries(this.state.collapse).forEach(([key, value]) => {
      if (value && key !== stateName)
        this.setState({
          collapse: { [key]: false },
        });
    });
    this.setState({
      collapse: {
        [stateName]: !this.state.collapse[stateName],
      },
    });
  }

  getSubRoutes = item => item.submenu.map(({ path }) => path);

  /** map menu config to string to determine which element to render */
  itemType = item => {
    if (item.heading) return 'heading';
    if (!item.submenu) return 'menu';
    if (item.submenu) return 'submenu';
  };

  render() {
    return (
      <aside className="aside-container">
        {/* START Sidebar (left) */}
        <div className="aside-inner">
          <nav data-sidebar-anyclick-close="" className="sidebar">
            {/* START sidebar nav */}
            <ul className="sidebar-nav">
              {/* START user info */}
              <li className="has-user-block">
                <SidebarUserBlock />
              </li>
              {/* END user info */}

              {/* Iterates over all sidebar items */}
              {Menu.map((item, i) => {
                if (!permitted(this.props.user, item)) return null;

                // heading
                if (this.itemType(item) === 'heading')
                  return <SidebarItemHeader item={item} key={i} />;
                else {
                  if (this.itemType(item) === 'menu')
                    return (
                      <SidebarItem
                        isActive={this.routeActive(item.path, item.exact)}
                        item={item}
                        key={i}
                      />
                    );
                  if (this.itemType(item) === 'submenu')
                    return [
                      <SidebarSubItem
                        item={item}
                        isOpen={this.state.collapse[item.name]}
                        handler={this.toggleItemCollapse.bind(this, item.name)}
                        isActive={this.routeActive(this.getSubRoutes(item))}
                        key={i}
                      >
                        <SidebarSubHeader item={item} key={i} />
                        {item.submenu.map((subitem, i) => (
                          <SidebarItem
                            key={i}
                            item={subitem}
                            isActive={this.routeActive(
                              subitem.path,
                              subitem.exact
                            )}
                          />
                        ))}
                      </SidebarSubItem>,
                    ];
                }
                return null; // unrecognized item
              })}
            </ul>
            {/* END sidebar nav */}
          </nav>
        </div>
        {/* END Sidebar (left) */}
      </aside>
    );
  }
}

SidebarItemHeader.propTypes = {
  item: PropTypes.object,
};

SidebarItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
};

SidebarSubItem.propTypes = {
  item: PropTypes.object,
  isActive: PropTypes.bool,
  handler: PropTypes.func,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
};

SidebarSubHeader.propTypes = {
  item: PropTypes.object,
};

Sidebar.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  settings: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = state => ({
  settings: state.settings,
  user: state.user,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translations')(withRouter(Sidebar)));
