import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import BasePage from './components/Layout/BasePage';
import PageLoader from './components/Common/PageLoader';
/* Used to render a lazy component with react-router */
// eslint-disable-next-line react/display-name
const waitFor = Tag => props => <Tag {...props} />;

const Login = lazy(() => import('./components/Pages/Login'));
const Recover = lazy(() => import('./components/Pages/Recover'));
const ImageView = lazy(() => import('./components/Pages/ImageView'));

const PublicRoutes = ({ location }) => (
  <BasePage>
    <Suspense fallback={<PageLoader />}>
      <Switch location={location}>
        <Route path="/login" component={waitFor(Login)} />
        <Route path="/recover" component={waitFor(Recover)} />
        <Route path="/preview" component={waitFor(ImageView)} />
        <Redirect to="/login" />
      </Switch>
    </Suspense>
  </BasePage>
);

PublicRoutes.propTypes = {
  location: PropTypes.object,
};

export default PublicRoutes;
